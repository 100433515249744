import React, {PureComponent} from 'react'
import './styles/header.scss'
import './styles/reset.scss'
import Logo from '../../images/logo.png'
import font from '../../fonts/Oswald-Light.woff'
import headBack from '../../images/head-back.jpg'
import headBannerImage from '../../images/headb.png'
import IconArrow from '../../images/icons/icon-arrow.png'
import IconEuro from '../../images/icons/icon-eur.png'
import IconSearch from '../../images/icons/icon-search.png'
import IconSearchBlack from '../../images/icons/icon-search-b.png'
import axios from 'axios'
import moment from 'moment'
import {withRouter} from 'react-router'
import { url,SEARCH_RU,SEARCH_UA } from "../../constants";


import {NavLink, Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import {getAllCategoriesSite} from "../../ac";


class HeaderSite extends PureComponent {

    constructor(props) {
        super(props)
        this.calculateWidth = this.calculateWidth.bind(this)
        this.clickHumburger = this.clickHumburger.bind(this)
        this.handleShowHideSearch = this.handleShowHideSearch.bind(this)
        this.handleClickMenu = this.handleClickMenu.bind(this)
        this.handleGetCurrency = this.handleGetCurrency.bind(this)
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        this.handleGetLinks = this.handleGetLinks.bind(this)
    }

    state = {
        secondMenuCountItems: 0,
        showHideMenu: false,
        displayMenu: 'flex',
        search: false,
        term: '',
        USD: '28.27',
        EUR: '28.27',
        EUR_Y: '28.27',
        USD_Y: '28.27',
        facebook: '',
        google_plus: '',
        telegram: '',
        twitter: ''
    }

    handleGetLinks = () => {
        axios.get(`${url}/api/main/settings`)
            .then(res => {
                this.setState({
                    ...res.data
                })
            })
            .catch(err => console.log(err))
    }

    handleGetCurrency = (curr) => {
        axios.get('https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json', {
            params: {
                valcode: curr,
                date: moment().format('YYYYMMDD')
            }
        })


            .then(res => {
                this.setState({
                    [curr] : res.data[0].rate
                },this.calculateWidth)
            })
            .catch(err => console.log(err));

    }

    handleSubmitSearch = (e) => {
        e.preventDefault()
        const term = e.target.elements.term.value
        const {locale, history} = this.props
        history.push({
            pathname: `/${locale}/search`,
            search: `?term=${term}`
        })
        this.setState({
            search: false
        })

    }

    currWas(curr) {
        axios.get('https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json', {
            params: {
                valcode: curr,
                date: moment().add(-1, 'days').format('YYYYMMDD')
            }
        })


           .then(res=>{
            this.setState({
                [curr+'_Y'] : res.data[0].rate
           },this.calculateWidth)
        })
            .catch(err=>console.log(err));
    }

    handleClickMenu = () => {
        this.setState({
            showHideMenu: false
        })
    }

    calculateWidth() {
        console.log('resize')
        if (window.innerWidth > 768) {
            this.setState({
                showHideMenu: true,
                displayMenu: 'flex'
            })
            let secItems = Array.prototype.slice.call(document.getElementsByClassName('sec-menu-item'))
            let arrowBlock = document.getElementsByClassName('arrow')[0] ? document.getElementsByClassName('arrow')[0].clientWidth + 52 : 0
            let blockCourses = document.getElementsByClassName('myCourse')[0] ? document.getElementsByClassName('myCourse')[0].clientWidth : 0
            let dots = document.getElementsByClassName('dots')[0] ? document.getElementsByClassName('dots')[0].clientWidth + 20 : 0
            let wrapper = document.getElementsByClassName('wrapperWidth')[0] ? document.getElementsByClassName('wrapperWidth')[0].clientWidth - 30 : 0
            let searchBlock = document.getElementsByClassName('search')[0] ? document.getElementsByClassName('search')[0].clientWidth + 18 : 0
            let langBlock = document.getElementsByClassName('langBox')[0] ? document.getElementsByClassName('langBox')[0].clientWidth + 18 : 0
            let maxWidth = wrapper - dots - blockCourses - arrowBlock - searchBlock - langBlock
            let widthSecItems = 0
            for (const [ind, el] of secItems.entries()) {
                widthSecItems += el.clientWidth + 40
                if (widthSecItems > maxWidth) {
                    this.setState({
                        secondMenuCountItems: ind
                    })
                    break;
                }
                else {
                    if (ind === secItems.length - 1) {
                        this.setState({
                            secondMenuCountItems: 0
                        })
                    }
                }
            }
        }
        else {
            this.setState({
                showHideMenu: false,
                displayMenu: 'block'
            })
        }
    }

    clickHumburger = () => {

        let shadow = document.querySelector('.header .nav-top .shadow');

        if (!this.state.showHideMenu)
            shadow.classList.add('active');  //тень в мобильном меню
        else shadow.classList.remove('active');

        this.setState({
            showHideMenu: !this.state.showHideMenu
        })
    }

    handleShowHideSearch = (bool) => {
        this.setState({
            search: bool
        })
    }

    handleSwitchLang = () => {
        const { locale } = this.props.match.params
        const { history } = this.props
        const changeLocale = locale === 'ru' ? 'ua' : 'ru'
        axios.get(`${url}/api/main/categories`,{
            params: {
                lang: changeLocale
            }
        })
            .then(res=>{

                let categories = res
                    .data
                    .sort((a,b) => parseInt(a.sortOrder) > parseInt(b.sortOrder) ? 1 : -1)
                this.props.getAllCategories(categories)
                window.dispatchEvent(new Event('resize'))
                history.replace(history.location.pathname.replace(locale,changeLocale)+history.location.search)
            })
            .catch(err=>console.log(err))


    }

    componentDidMount() {

        window.addEventListener('resize', this.calculateWidth)
        window.addEventListener('orientationchange',this.calculateWidth)
        this.handleGetCurrency('USD')
        this.handleGetCurrency('EUR')
        setTimeout(this.calculateWidth, 700)

        this.currWas('USD');
        this.currWas('EUR');

        this.handleGetLinks()
    }

    render() {
        const {
            secondMenuCountItems,
            showHideMenu,
            displayMenu,
            search,
            USD,
            EUR,
            EUR_Y,
            USD_Y,
            facebook,
            google_plus,
            twitter,
            telegram
        } = this.state
        const {locale, categories} = this.props
        const windowWidth = window.innerWidth
        const classHumb = showHideMenu ? 'hamburger hbg-open' : 'hamburger'
        const SEARCH = locale === 'ru' ? SEARCH_RU : SEARCH_UA
        const MORE = locale === 'ru' ? 'ЕЩЕ...' : 'ЩЕ...'
        return (
            <header className="site-header">
                <div className="header-top">
                    <div className="inner">
                        <div className="header-top__container">
                            <div className="search-form">
                                {/*<form action="">*/}
                                {/*    <input type="text" placeholder="Поиск...">*/}
                                {/*        <button type="submit">*/}
                                {/*            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451 451">*/}
                                {/*                <use xlink:href="#icon-search"></use>*/}
                                {/*            </svg>*/}
                                {/*        </button>*/}
                                {/*</form>*/}
                                <div className="searchBtn">
                                    <p>поиск</p>
                                    <img onClick={() => this.handleShowHideSearch(!search)} src={IconSearchBlack} alt=""/>
                                </div>
                                <form style={{display: search && windowWidth < 768 ? 'block' : 'none'}}
                                      onSubmit={this.handleSubmitSearch}>
                                    <input name='term' className="searchField" type="text" placeholder={SEARCH}/>
                                </form>
                                <div className="search-form__close">
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">*/}
                                    {/*    <use xlink:href="#icon-close"/>*/}
                                    {/*</svg>*/}
                                </div>
                            </div>
                            <div className="header-top__left">
                                <div className="touch-menu">
                                    <div className="touch-menu__open">
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">*/}
                                        {/*    <use xlink:href="#icon-open"/>*/}
                                        {/*</svg>*/}
                                    </div>
                                    <div className="touch-menu__close">
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">*/}
                                        {/*    <use xlink:href="#icon-close"/>*/}
                                        {/*</svg>*/}
                                    </div>
                                </div>
                                <div className="header-menu">
                                    <ul>
                                        <li><a href="photo-video.html">Фото и видео</a></li>
                                        <li><a href="lenta.html">Лента</a></li>
                                        <li><a href="art.html">ART</a></li>
                                        <li><a href="persons.html">Досье</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="header-top__right">
                                <div className="header-social">
                                    <a target="_blank" rel="nofollow" href={facebook} className="social-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-110 1 511 512">
                                            <g id="icon-facebook">
                                                <path d="m180 512h-81.992188c-13.695312 0-24.835937-11.140625-24.835937-24.835938v-184.9375h-47.835937c-13.695313 0-24.835938-11.144531-24.835938-24.835937v-79.246094c0-13.695312 11.140625-24.835937 24.835938-24.835937h47.835937v-39.683594c0-39.347656 12.355469-72.824219 35.726563-96.804688 23.476562-24.089843 56.285156-36.820312 94.878906-36.820312l62.53125.101562c13.671875.023438 24.792968 11.164063 24.792968 24.835938v73.578125c0 13.695313-11.136718 24.835937-24.828124 24.835937l-42.101563.015626c-12.839844 0-16.109375 2.574218-16.808594 3.363281-1.152343 1.308593-2.523437 5.007812-2.523437 15.222656v31.351563h58.269531c4.386719 0 8.636719 1.082031 12.289063 3.121093 7.878906 4.402344 12.777343 12.726563 12.777343 21.722657l-.03125 79.246093c0 13.6875-11.140625 24.828125-24.835937 24.828125h-58.46875v184.941406c0 13.695313-11.144532 24.835938-24.839844 24.835938zm-76.8125-30.015625h71.632812v-193.195313c0-9.144531 7.441407-16.582031 16.582032-16.582031h66.726562l.027344-68.882812h-66.757812c-9.140626 0-16.578126-7.4375-16.578126-16.582031v-44.789063c0-11.726563 1.191407-25.0625 10.042969-35.085937 10.695313-12.117188 27.550781-13.515626 39.300781-13.515626l36.921876-.015624v-63.226563l-57.332032-.09375c-62.023437 0-100.566406 39.703125-100.566406 103.609375v53.117188c0 9.140624-7.4375 16.582031-16.578125 16.582031h-56.09375v68.882812h56.09375c9.140625 0 16.578125 7.4375 16.578125 16.582031zm163.0625-451.867187h.003906zm0 0"/>
                                            </g>
                                        </svg>
                                    </a>
                                    <a target="_blank" href={telegram} className="social-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.473 189.473">
                                            <g id="icon-telegram">
                                                <path d="M152.531,179.476c-1.48,0-2.95-0.438-4.211-1.293l-47.641-32.316l-25.552,18.386c-2.004,1.441-4.587,1.804-6.914,0.972c-2.324-0.834-4.089-2.759-4.719-5.146l-12.83-48.622L4.821,93.928c-2.886-1.104-4.8-3.865-4.821-6.955c-0.021-3.09,1.855-5.877,4.727-7.02l174.312-69.36c0.791-0.336,1.628-0.53,2.472-0.582c0.302-0.018,0.605-0.018,0.906-0.001c1.748,0.104,3.465,0.816,4.805,2.13c0.139,0.136,0.271,0.275,0.396,0.42c1.11,1.268,1.72,2.814,1.835,4.389c0.028,0.396,0.026,0.797-0.009,1.198c-0.024,0.286-0.065,0.571-0.123,0.854L159.898,173.38c-0.473,2.48-2.161,4.556-4.493,5.523C154.48,179.287,153.503,179.476,152.531,179.476z M104.862,130.579l42.437,28.785L170.193,39.24l-82.687,79.566l17.156,11.638C104.731,130.487,104.797,130.533,104.862,130.579z M69.535,124.178l5.682,21.53l12.242-8.809l-16.03-10.874C70.684,125.521,70.046,124.893,69.535,124.178z M28.136,86.782l31.478,12.035c2.255,0.862,3.957,2.758,4.573,5.092l3.992,15.129c0.183-1.745,0.974-3.387,2.259-4.624L149.227,38.6L28.136,86.782z"/>
                                            </g>
                                        </svg>
                                    </a>
                                    <a href="#" className="social-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                            <g id="icon-youtube">
                                                <path d="M30,12a5.71,5.71,0,0,0-5.31-5.7C18.92,6,13.06,6,7.33,6.28,4.51,6.28,2,9,2,12a43.69,43.69,0,0,0,0,8.72,5.32,5.32,0,0,0,5.28,5.33h0q4.35.24,8.72.24t8.67-.23A5.34,5.34,0,0,0,30,20.8,31.67,31.67,0,0,0,30,12Zm-2,8.63a.49.49,0,0,0,0,.12,3.36,3.36,0,0,1-3.39,3.34,166,166,0,0,1-17.28,0A3.36,3.36,0,0,1,4,20.65a42,42,0,0,1,0-8.47.45.45,0,0,0,0-.11A3.78,3.78,0,0,1,7.38,8.28c2.86-.13,5.74-.19,8.62-.19s5.76.06,8.62.19h.05c1.71,0,3.33,1.84,3.33,3.79a.76.76,0,0,0,0,.15A30.11,30.11,0,0,1,28,20.61Z"/><path d="M20.79,15.51l-7.14-3.68a1,1,0,1,0-.92,1.78l5.43,2.79-4,2.07V16.4a1,1,0,0,0-2,0v3.72a1,1,0,0,0,1,1,1,1,0,0,0,.46-.11l7.14-3.72a1,1,0,0,0,.54-.89A1,1,0,0,0,20.79,15.51Z"></path>
                                            </g>
                                        </svg>
                                    </a>
                                    <a href="#" className="social-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.063 169.063">
                                            <g id="icon-instagram">
                                                <path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752c17.455,0,31.656,14.201,31.656,31.655V122.407z"/>
                                                <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"/>
                                                <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78C135.661,29.421,132.821,28.251,129.921,28.251z"/>
                                            </g>
                                        </svg>
                                    </a>
                                </div>
                                <div className="header-login">
                                    <a href="#" className="popup-handler" rel="#popup-login">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 483 483">
                                            <g id="icon-user">
                                                <path d="M239.7,260.2c0.5,0,1,0,1.6,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1,0c29.3-0.5,53-10.8,70.5-30.5c38.5-43.4,32.1-117.8,31.4-124.9c-2.5-53.3-27.7-78.8-48.5-90.7C280.8,5.2,262.7,0.4,242.5,0h-0.7c-0.1,0-0.3,0-0.4,0h-0.6c-11.1,0-32.9,1.8-53.8,13.7c-21,11.9-46.6,37.4-49.1,91.1c-0.7,7.1-7.1,81.5,31.4,124.9C186.7,249.4,210.4,259.7,239.7,260.2zM164.6,107.3c0-0.3,0.1-0.6,0.1-0.8c3.3-71.7,54.2-79.4,76-79.4h0.4c0.2,0,0.5,0,0.8,0c27,0.6,72.9,11.6,76,79.4c0,0.3,0,0.6,0.1,0.8c0.1,0.7,7.1,68.7-24.7,104.5c-12.6,14.2-29.4,21.2-51.5,21.4c-0.2,0-0.3,0-0.5,0l0,0c-0.2,0-0.3,0-0.5,0c-22-0.2-38.9-7.2-51.4-21.4C157.7,176.2,164.5,107.9,164.6,107.3z"/>
                                                <path d="M446.8,383.6c0-0.1,0-0.2,0-0.3c0-0.8-0.1-1.6-0.1-2.5c-0.6-19.8-1.9-66.1-45.3-80.9c-0.3-0.1-0.7-0.2-1-0.3c-45.1-11.5-82.6-37.5-83-37.8c-6.1-4.3-14.5-2.8-18.8,3.3c-4.3,6.1-2.8,14.5,3.3,18.8c1.7,1.2,41.5,28.9,91.3,41.7c23.3,8.3,25.9,33.2,26.6,56c0,0.9,0,1.7,0.1,2.5c0.1,9-0.5,22.9-2.1,30.9c-16.2,9.2-79.7,41-176.3,41c-96.2,0-160.1-31.9-176.4-41.1c-1.6-8-2.3-21.9-2.1-30.9c0-0.8,0.1-1.6,0.1-2.5c0.7-22.8,3.3-47.7,26.6-56c49.8-12.8,89.6-40.6,91.3-41.7c6.1-4.3,7.6-12.7,3.3-18.8c-4.3-6.1-12.7-7.6-18.8-3.3c-0.4,0.3-37.7,26.3-83,37.8c-0.4,0.1-0.7,0.2-1,0.3c-43.4,14.9-44.7,61.2-45.3,80.9c0,0.9,0,1.7-0.1,2.5c0,0.1,0,0.2,0,0.3c-0.1,5.2-0.2,31.9,5.1,45.3c1,2.6,2.8,4.8,5.2,6.3c3,2,74.9,47.8,195.2,47.8s192.2-45.9,195.2-47.8c2.3-1.5,4.2-3.7,5.2-6.3C447,415.5,446.9,388.8,446.8,383.6z"/>
                                            </g>
                                        </svg>
                                        <span>Вход</span>
                                    </a>
                                </div>
                                <div className="header-search">
                                    <div className="header-search__handler">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451 451">
                                            <g id="icon-search">
                                                <path d="M447.05,428l-109.6-109.6c29.4-33.8,47.2-77.9,47.2-126.1C384.65,86.2,298.35,0,192.35,0C86.25,0,0.05,86.3,0.05,192.3s86.3,192.3,192.3,192.3c48.2,0,92.3-17.8,126.1-47.2L428.05,447c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4C452.25,441.8,452.25,433.2,447.05,428zM26.95,192.3c0-91.2,74.2-165.3,165.3-165.3c91.2,0,165.3,74.2,165.3,165.3s-74.1,165.4-165.3,165.4C101.15,357.7,26.95,283.5,26.95,192.3z"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-main">
                    <div className="inner">
                        <div className="header-main__container">
                            <div className="header-main__left">
                                <a href="/" className="header-logo">
                                    <div className="header-logo__image"><Link to={`/${locale}`}><img src={Logo} alt=""/></Link></div>
                                    <div className="header-logo__text">Україна проти коорупції</div>
                                </a>
                            </div>
                            <div className="header-main__right"><Link to={`/${locale}`}><img src={headBannerImage} alt=""/></Link></div>
                        </div>
                    </div>
                </div>
                {categories.length > 0 &&
                <nav className="header-nav">
                    <div className="inner">
                        <ul>
                            <li className="arrow"
                                style={{width: 93}}>{/*{activeCategory ? <img src={IconNext} alt=""/> : null} */}<a
                                href="#"></a></li>
                            {getListCategories(this.handleClickMenu, categories.slice(0), locale, secondMenuCountItems, 'sec-menu-item')}
                        </ul>
                    </div>
                </nav>
                }
            </header>
        )
    }
}

HeaderSite.defaultProps = {
    categories: []
}

const getListCategories = (handleClick, categories = [], locale, count, nameClass) => {
    if (!count || count === 0)
        return categories.map(category => <li className={nameClass !== undefined ? `${nameClass}` : ''}
                                              key={category.id}><NavLink onClick={handleClick}
                                                                         to={`/${locale}/${category.url}`}>{category.name}</NavLink>
        </li>)
    else {
        let newClass = nameClass !== undefined ? `${nameClass}` : ''
        return categories.map((category, ind) => <li className={ind > count - 1 ? newClass + ' hideItem' : newClass}
                                                     key={category.id}><NavLink
            to={`/${locale}/${category.url}`}>{category.name}</NavLink>
        </li>)
    }

}

const mapDispatchToProps = dispatch => {
    return {
        getAllCategories: bindActionCreators(getAllCategoriesSite,dispatch),
    }
}

const mapStateToProps = state => {
    return {
        categories: state.siteReducer.categories
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderSite))